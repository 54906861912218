import debounce from 'lodash/debounce';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';

import { Note, SpanLabel, StyledTextarea } from './styled';

type Props = {
  updateValue: (value: string) => void;
  value: string;
  label: string;
  placeholder?: string;
  note?: string;
};

export const TextareaBlock = ({ updateValue, value, label, placeholder, note }: Props) => {
  const isInitialRender = useRef(true);
  const [localValue, setLocalValue] = useState(value);

  useEffect(() => {
    if (!isInitialRender.current) {
      debouncedChangeHandler();
    }
    return () => {
      debouncedChangeHandler.cancel();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue]);

  useEffect(() => {
    isInitialRender.current = false;
  }, []);

  const changeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setLocalValue(event.currentTarget.value);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedChangeHandler = useMemo(() => debounce(() => updateValue(localValue), 300), [localValue]);

  return (
    <label>
      <SpanLabel>{label}</SpanLabel>
      {note && <Note>{note}</Note>}
      <StyledTextarea
        value={localValue}
        placeholder={placeholder}
        onBlur={() => updateValue(localValue)}
        onChange={(event: ChangeEvent<HTMLTextAreaElement>) => {
          debouncedChangeHandler();
          changeHandler(event);
        }}
      />
    </label>
  );
};
